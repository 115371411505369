import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Template from '../layouts/Template';

const BlogPostTemplate = (props) => {
	const { data } = props;
	const post = data.markdownRemark;
	const siteTitle = data.site.siteMetadata.title;

	let image = null;
	if (post.frontmatter && post.frontmatter.featuredImage) {
		// @todo probably not make myself.
		image = `https://tylerzey.com${post.frontmatter.featuredImage.childImageSharp.fixed.src}`;
	}

	return (
		<Template img={image} title={`${post.frontmatter.title} | ${siteTitle}`} desc={post.excerpt}>
			<Helmet>
				<meta property="og:type" content="article" />
			</Helmet>
			<BlogPost className="blog">
				<header className="container">
					<h1 className="title">{post.frontmatter.title}</h1>
					<div>
						<span className="posted">Posted on </span>
						<span className="date">{post.frontmatter.date}</span>
					</div>
				</header>
				<div className="container">
					<StyledP dangerouslySetInnerHTML={{ __html: post.html }} />
				</div>
			</BlogPost>
		</Template>
	);
};

BlogPostTemplate.propTypes = {
	data: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				author
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			excerpt
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				featuredImage {
					childImageSharp {
						fixed(width: 1200) {
							src
						}
					}
				}
			}
		}
	}
`;

const BlogPost = styled.section`
	.posted {
		${tw`font-serif italic font-bold opacity-33 pr-1`};
	}
	.date {
		${tw`mt-2 mb-4 text-14 text-primary-alt`};
	}
`;
const StyledP = styled.div`
	${tw`mt-8`};
	max-width: 750px;

	h1 {
		${tw`lg:text-48`};
	}
	h1,
	h2 {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		${tw`mb-8`}
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		${tw`my-5`}
	}

	h2 {
		${tw`text-32`}
	}
	h3 {
		${tw`text-24`}
	}
	h4 {
		${tw`text-20`}
	}

	p {
		${tw`text-20 font-sans leading-1.65`};
	}
	a {
		${tw`border-primary-alt`};
		padding: 3px;
		&:hover {
			${tw`bg-primary-alt text-primary`};
		}
	}
	pre {
		max-width: 100%;
		overflow: scroll;
		padding: 2em 1em;
		background: #2a2831;
		${tw`rounded`}
	}
	code {
		color: #e3b505;
	}
	ol,
	ul {
		${tw`text-20 font-sans leading-1.65 mb-4`};
		a {
			border-bottom: 3px solid #00cf9e;
			&:hover {
				${tw`bg-primary-alt text-primary`};
			}
		}
		li {
			${tw`mb-2`}
		}
	}
`;
